import React from 'react';

interface Props {
  cols: 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
  items?: 'start' | 'center' | 'end';
  gap?: string;
  className?: string;
  id?: string;
}

const Grid: React.FC<Props> = ({ cols, children, items = 'start', gap, className, id }) => {
  let classes = `grid grid-cols-1 ${gap ? gap : 'gap-4'} ${className ? className : ''}`;

  switch (items) {
    case 'start':
      classes += ' items-start';
      break;
    case 'center':
      classes += ' items-center';
      break;
    case 'end':
      classes += ' items-end';
      break;
  }

  switch (cols) {
    case 2:
      classes += ' md:grid-cols-2';
      break;
    case 3:
      classes += ' md:grid-cols-3';
      break;
    case 4:
      classes += ' md:grid-cols-4';
      break;
    case 5:
      classes += ' md:grid-cols-5';
      break;
    case 6:
      classes += ' md:grid-cols-6';
      break;
  }

  return (
    <div id={id ? id : ''} className={`${classes}`}>
      {children}
    </div>
  );
};

export default Grid;
