import { graphql, Link } from 'gatsby';
import React from 'react';
import Button from '~components/common/Button';
import Container from '~components/common/Container';
import Disclosure from '~components/common/Disclosure';
import FeatureList from '~components/common/FeatureList';
import ImageVideo from '~components/common/ImageVideo';
import Layout from '~components/layouts/Default';
import Cta from '~components/sections/Cta';
import PageHeader from '~components/sections/PageHeader';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';

// Icons
import { GiCandleHolder } from '@react-icons/all-files/gi/GiCandleHolder';
import { GiCigarette } from '@react-icons/all-files/gi/GiCigarette';
import { GiBeerStein } from '@react-icons/all-files/gi/GiBeerStein';
import { GiSaltShaker } from '@react-icons/all-files/gi/GiSaltShaker';
import { GiBrainstorm } from '@react-icons/all-files/gi/GiBrainstorm';
import { GiPill } from '@react-icons/all-files/gi/GiPill';
import { GiKnifeFork } from '@react-icons/all-files/gi/GiKnifeFork';
import { GiMeditation } from '@react-icons/all-files/gi/GiMeditation';
import { FaWeight } from '@react-icons/all-files/fa/FaWeight';

function Page({ location, data }) {
  return (
    <Layout location={location} title="Bluthochdruck senken, Autogenes Training hilft" desc="" active="Bluthochdruck" green>
      <PageHeader title="Bluthochdruck senken, Autogenes Training hilft" image={data.bildHeader} classNameImage="rounded-2xl mx-auto" wrapperClass="">
        <P className="mb-3">
          Viele Menschen leiden unter Bluthochdruck. In Deutschland sind es etwa 20 bis 30 Millionen Menschen. Mit steigendem Alter steigt auch Risiko. Drei von vier Menschen zwischen 70 und 79 Jahren leiden an Bluthochdruck. Seit 1990 habe
          sich die Zahl der Menschen mit der sogenannten Hypertonie verdoppelt, nämlich auf 1,3 Milliarden weltweit.
        </P>
        <P className="mb-3">
          Bluthochdruck macht zwar erst einmal keine direkten Beschwerden, ist jedoch Risikofaktor Nummer eins für Herz-Kreislauf-Erkrankungen. Beim Bluthochdruck kann von einer Lebensstilerkrankung gesprochen werden, die unterschiedliche
          Ursachen haben kann, die wir hier näher ausführen werden.
        </P>
        <P className="mb-6">In diesem Artikel liest du:</P>

        <FeatureList
          items={[
            { text: 'Was ist der Blutdruck eigentlich?' },
            { text: 'Wie sollte der optimale Blutdruck sein?' },
            { text: 'Symptome bei Bluthochdruck' },
            { text: 'Risikofaktoren für Bluthochdruck' },
            { text: 'Schutzfaktoren gegen Bluthochdruck' },
            { text: 'Autogenes Training hilft gegen Bluthochdruck' }
          ]}
          className="space-y-5"
        />
      </PageHeader>

      <Container className="text-brand-gray">
        <div className="mx-auto max-w-2xl space-y-8">
          <div>
            <H2 className="mb-5 font-display">Doch was ist der Blutdruck überhaupt?</H2>
            <P>
              Das wohl wichtigste Organ im menschlichen Körper ist wohl das Herz, denn es versorgt alle Organe und das Gewebe im Körper mit Blut. Unser Herz schlägt schätzungsweise 100.000 mal am Tag und hat in der Regel einen Ruhepuls von
              60 - 80, bei sportlichen Menschen fällt der Ruhepuls deutlich geringer aus, das Herz muss also weniger oft schlagen um die Selbe Menge Blut durch den Körper zu transportieren, weil der Herzmuskel trainiert und damit
              leistungsfähiger ist. Mit jedem Herzschlag zieht sich der Herzmuskel zusammen und pumpt Blut in die Gefäße des Blutkreislaufs. Der hierbei entstehende Druck auf die Gefäßwände ist der Blutdruck. Zwei wesentliche Blutdruckdaten
              werden in Messungen dokumentiert.
            </P>
          </div>

          <div>
            <H2 className="mb-5 font-display">Wichtige Kennzahlen für deinen Blutdruck</H2>
            <P className="mb-3">
              Der systolische Druck wird beim Herzschlag gemessen und sagt aus wieviel Druck beim zusammenziehen des Herzmuskels entsteht und so Sauerstoffangereichertes Blut in die Gefäße pumpt. Das kannst du dir vorstellen wie einen
              Schwamm den man auspresst.
            </P>
            <P className="mb-3">
              Der Gegenspieler ist der diastolische Blutdruck und misst den Druck auf die Gefäße während der Erschlaffung des Herzmuskels. Logischerweise muss dieser Wert also geringer ausfallen. Im Bildnis des Schwamms, würde hier die
              Flüssigkeit wieder eingesaugt werden.{' '}
            </P>
            <P>
              Eine Blutdruckmessung über entsprechende Geräte kann Aufschluss über die Gesundheit der Gefäße und des Herzens geben und sollte annähernde Werte von 120 mmHg - 80 mmHg beziehungsweise die Grenzwerte von 140 mmHg - 90 mmHg
              nicht übersteigen. Für eine aussagekräftige Messung sollte zu unterschiedlichen Zeitpunkten am Tag gemessen und dokumentiert werden. Ergeben mehrere dieser Messungen erhöhte Werte, ist die Rede von Bluthochdruck bzw.
              Hypertonie, auch wenn nur einer der beiden Werte erhöht ist.{' '}
            </P>
          </div>

          <Cta headOne="Autogenes Training Onlinekurs" headTwo="Hilft gegen Bluthochdruck!" button={<Button to="/kurse/autogenes-training-onlinekurs/" text="Zum Kurs" />} withoutContainer />

          <div>
            <H2 className="mb-5 font-display">Symptome bei Bluthochdruck</H2>
            <P className="mb-3">
              Ohne eine Blutdruckmessung fällt Hypertonie erst einmal garnicht richtig auf und ist bei minimalen Abweichungen nicht spürbar. Bei starken Abweichungen jedoch klagen Betroffene über Symptome wie Angst, Atemnot,
              Abgeschlagenheit, Engegefühle in der Brust, Erschöpfung, Durchblutungsstörungen, innere Unruhe, Kopfschmerzen, Müdigkeit, Nasenbluten, Ohrensausen, einen geröteten Kopf, Schlafstörungen, Schwindel bis hin zu Sehstörungen.
            </P>
            <P className="mb-3">
              Bei derartigen Symptomen sollten Betroffene den Hausarzt kontaktieren und sich für eine Behandlung entscheiden. Diese kann über medikamentöse Einstellung erfolgen, oder aus der Veränderung des Lebensstils bestehen, was
              natürlich die nachhaltigere Lösung ist.{' '}
            </P>
            <P>Bluthochdruck erhöht außerdem das Risiko auf Herzinfarkte, Schlaganfälle sowie Herz- und Nierenschwäche und sollte daher ernst genommen werden. </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Die Anpassungsfähigkeit des Herzens</H2>
            <P>
              Das menschliche Herz ist in der Lage den Herzschlag auf die jeweiligen Situationen im Leben anzupassen. Früher war das sehr einfach, der Blutdruck hat sich während der Jagd und in Bewegung (Flucht oder Kampf) erhöht und sich
              nach Erfolg, oder erfolgreicher Flucht wieder reduziert. Das Herz verfügte über eine gute <strong>Herzratenvariabilität</strong>, das ist die eben beschriebene adäquate Anpassung auf jeweilige Situationen. Durch einen
              einfachen Lebensstil und das regelmäßige und notwendige Herz-Kreislauf-Training bei der Nahrungsbeschaffung war das menschliche Herz gut trainiert und hatte klar abgegrenzte Aktivitäts- und Ruhephasen. Leider hat sich unser
              Leben dahingehend verkompliziert, so dass unser Herz manches mal nicht mehr recht deuten kann, wann es einen Gang hoch- oder runterschalten sollte.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Mögliche Ursachen für Bluthochdruck</H2>
            <P className="mb-3">
              Spätestens jetzt wird dir als LeserIn vielleicht auch klar, wieso der Blutdruck so eng mit deinem Lebensstil verbunden ist. Sicherlich kannst du dir vorstellen, dass ein stressreicher Alltag, voller Termindruck,
              Unzufriedenheit im Job und Freizeitstress durch viele Verpflichtungen eine negative Konsequenz auf deinen Blutdruck hat. Das dieser durch deinen Lebensstil chronisch ansteigen kann.
            </P>
            <P className="mb-3">
              Während Stress extrem häufig in Verbindung mit Hypertonie steht, gibt es aber auch noch andere Dinge die du für dich reflektieren kannst. Klare Ursachen herauszustellen ist häufig garnicht so leicht, deshalb ist eine
              ganzheitliche Betrachtung und Therapie auch immer am erfolgsversprechender als eine medikamentöse Einstellung.
            </P>
            <dl className="space-y-3">
              <Disclosure
                title={
                  <div className="flex items-center">
                    <GiCandleHolder className="mr-3 inline-block" size={24} />
                    Steigendes Alter
                  </div>
                }
              >
                <P>
                  Je älter du wirst, desto höher ist die Wahrscheinlichkeit auf Bluthochdruck. Gleichzeitig machen sich häufig die ersten körperlichen Wehwehchen bemerkbar, was die Bewegung an sich einschränkt. Dagegen gibt es nur eine
                  Lösung: Bleib im Training. Daher darfst du auch mit zunehmenden Alter Laufen, Radfahren, Schwimmen, Walken oder Rudern.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <FaWeight className="mr-3 inline-block" size={24} />
                    Übergewicht
                  </div>
                }
              >
                <P>
                  Ungesunde Ernährung und Bewegungsmangel lassen nicht nur deine Waage stöhnen. Auch dein Herz muss durch die abfallende Leistungsfähigkeit bei gleichzeitig erhöhter Anforderung mehr arbeiten. Schließlich muss jedes
                  Kilogramm bewegt und getragen werden. Übergewicht ist daher nicht nur schlecht für deine Gelenk, Selbstbewusstsein und deine Seele, sondern auch für dein Herz. Klar ein Auto das einen Wohnwagen zieht, verbraucht ja auch
                  mehr als ein Mini.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <GiCigarette className="mr-3 inline-block" size={24} />
                    Rauchen
                  </div>
                }
              >
                <P>
                  Sicherlich sind dir beim letzten Einkauf die Bildchen an der Kasse aufgefallen, die dir sehr deutlich vor Augen führen, welchen Einfluss auf Lunge, und Herz-Kreislauf-System und Durchblutung nehmen. Möchtest du wirklich
                  alt werden, empfehle ich dir mit dem Rauchen aufzuhören. Ein gutes Rauchfreicoaching kostet in der Regel deutlich weniger als 3 Monate ketterauchen und lohnt sich nachhaltig. Du senkst das Erkrankungsrisiko für deine
                  Gefäße und verhinderst so im besten Falle eine Amputation nicht mehr durchbluteter Körperteile.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <GiBeerStein className="mr-3 inline-block" size={24} />
                    Alkohol
                  </div>
                }
              >
                <P className="mb-3">
                  Sicherlich sind dir beim letzten Einkauf die Bildchen an der Kasse aufgefallen, die dir sehr deutlich vor Augen führen, welchen Einfluss auf Lunge, und Herz-Kreislauf-System und Durchblutung nehmen. Möchtest du wirklich
                  alt werden, empfehle ich dir mit dem Rauchen aufzuhören. Ein gutes Rauchfreicoaching kostet in der Regel deutlich weniger als 3 Monate ketterauchen und lohnt sich nachhaltig. Du senkst das Erkrankungsrisiko für deine
                  Gefäße und verhinderst so im besten Falle eine Amputation nicht mehr durchbluteter Körperteile.
                </P>
                <P className="text-sm">Quellen:</P>
                <ol className="mt-3 list-decimal space-y-3 pl-3 text-sm">
                  <li className="list-item">
                    <a href="https://www.science.org/doi/10.1126/scitranslmed.3005870" target="_blank" rel="noreferrer noopener">
                      https://www.science.org/doi/10.1126/scitranslmed.3005870
                    </a>
                  </li>
                  <li className="list-item">
                    <a href="https://www.sciencedaily.com/releases/2015/02/150206111702.htm" target="_blank" rel="noreferrer noopener">
                      https://www.sciencedaily.com/releases/2015/02/150206111702.htm
                    </a>
                  </li>
                  <li className="list-item">
                    <a href="https://www.sciencedaily.com/releases/2013/08/130829214354.htm" target="_blank" rel="noreferrer noopener">
                      https://www.sciencedaily.com/releases/2013/08/130829214354.htm
                    </a>
                  </li>
                </ol>
              </Disclosure>

              <Disclosure
                title={
                  <div className="flex items-center">
                    <GiSaltShaker className="mr-3 inline-block" size={24} />
                    Salzige Ernährung
                  </div>
                }
              >
                <P>
                  Bluthochdruck kann durch erhöhten Salzkonsum gefördert werden. Insbesondere in Fertigprodukten wie Wurst und Käse, aber auch in dunklen Brotsorten steckt viel Salz. Leider lässt sich heute durch die Produktions- und
                  Herstellungswege ein erhöhter Salzkonsum kaum vermeiden. Doch insbesondere Blutdruckpatienten profitieren von einer Reduzierung der Würze. Bis zu 5 mmHg können systolisch, wie diastolisch gesenkt werden.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" className="mr-3 inline-block">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M14.27 6c-.55.95-.22 2.18.73 2.73.95.55 2.18.22 2.73-.73.55-.95.22-2.18-.73-2.73-.95-.55-2.18-.22-2.73.73z"></path>
                      <path d="M15.84 10.41l-2.6-1.5c-2.38-1.38-3.2-4.44-1.82-6.82l-1.73-1C8.1 3.83 8.6 7.21 10.66 9.4l-5.15 8.92 1.73 1 1.5-2.6 1.73 1-3 5.2 1.73 1 6.29-10.89a5.002 5.002 0 01.31 5.46l1.73 1c1.6-2.75 1.28-6.58-1.69-9.08zM12.75 3.8c.72.41 1.63.17 2.05-.55.41-.72.17-1.63-.55-2.05a1.501 1.501 0 00-1.5 2.6z"></path>
                    </svg>
                    Bewegungsarmut
                  </div>
                }
              >
                <P>
                  Dein Herz ist ein Muskel. Jeder Muskel der nicht trainiert wird verkümmert und schrumpft. Wäre dein Herz ein Computer sorgt Bewegungsarmut dafür, dass du den Arbeitsspeicher reduzierst in dem du eine Platine Chips
                  entfernst. Auf die Idee würdest du nie kommen oder? Dein Herz benötigt regelmäßiges Training und muss gefordert werden, damit die Herzratenvariabilität erhalten bleibt.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <GiBrainstorm className="mr-3 inline-block" size={24} />
                    Chronischer Stress
                  </div>
                }
              >
                <P>
                  Stressige Lebensphasen haben wir alle einmal, darauf kann sich dein Herz gut drauf einstellen. Doch wenn diese Lebensphasen deinen Alltag bestimmen, kann es problematisch werden. Sympathikus und Parasympathikus geraten aus
                  dem Gleichgewicht und dein Herz verliert seine natürliche Regulationsfähigkeit. So fährst du mit durchgedrücktem Gaspedal und erhöhter Drehzahl über die Autobahn, bis dein Motor qualmt.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <GiPill className="mr-3 inline-block" size={24} />
                    Bestimmte Medikamente
                  </div>
                }
              >
                <P>
                  Medikamente sind eine Mehrbelastung für deinen Organismus, die von deinen Organen wahrgenommen werden. Eine Reaktion auf Medikamenteneinnahme kann beispielsweise eine Gefäßverengung oder die Blutverdünnung sein, was
                  ebenfalls zu erhöhtem Blutdruck führen kann. Lies dir daher den Beipackzettel durch und verzichte wenn möglich auf die Einnahme von Medikamenten.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <svg stroke="currentColor" fill="currentColor" className="mr-3 inline-block" strokeWidth="0" viewBox="0 0 640 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg">
                      <path d="M616,304a24,24,0,1,0-24-24A24,24,0,0,0,616,304ZM552,416a24,24,0,1,0,24,24A24,24,0,0,0,552,416Zm-64-56a24,24,0,1,0,24,24A24,24,0,0,0,488,360ZM616,464a24,24,0,1,0,24,24A24,24,0,0,0,616,464Zm0-104a24,24,0,1,0,24,24A24,24,0,0,0,616,360Zm-64-40a24,24,0,1,0,24,24A24,24,0,0,0,552,320Zm-74.78-45c-21-47.12-48.5-151.75-73.12-186.75A208.13,208.13,0,0,0,234.1,0H192C86,0,0,86,0,192c0,56.75,24.75,107.62,64,142.88V512H288V480h64a64,64,0,0,0,64-64H320a32,32,0,0,1,0-64h96V320h32A32,32,0,0,0,477.22,275ZM288,224a32,32,0,1,1,32-32A32.07,32.07,0,0,1,288,224Z"></path>
                    </svg>
                    Erkrankungen
                  </div>
                }
              >
                <P>
                  Auch Erkrankungen anderer Organe können Bluthochdruck begünstigen. Hierzu zählen insbesondere Störungen der Schilddrüse, Herzfehler, Arteriosklerose aber auch beispielsweise die obstruktive Schlafapnoe. Auch Gene spielen
                  wohl in seltenen Fällen eine Rolle. Jedoch ist die Rolle der Gene dem Lebensstil unterzuordnen. Das bedeutet also nicht, dass du automatisch mit dem Blutdruck zu kämpfen hast, nur weil in deiner Familie häufiger
                  Herz-Kreislauf-Erkrankungen vorkommen.
                </P>
              </Disclosure>
            </dl>
          </div>

          <div>
            <H2 className="mb-5 font-display">Was hilft gegen Bluthochdruck? </H2>
            <P className="mb-3">
              Da du dich mit den möglichen Ursachen bereits auseinandergesetzt hast, ist dir sicherlich auch schon klar, welche Schutzfaktoren du ergreifen kannst, um zu verhindern, dass du an Bluthochdruck erkrankst. Die wichtigsten Punkte
              habe ich dir noch einmal zusammengefasst und empfehle dir diese Dinge zu beachten. Probiere aus welche der folgenden Methoden für dich am besten funktionieren und nutze sie regelmäßig. Dein Herz ist ein trainierbarer Muskel,
              das seinen Trainingszustand verändern kann. Doch wie mit deinem Biceps funktioniert auch das in beide Richtungen. Daher ist auch hier die Kontinuität der Schlüssel zum Erfolg.
            </P>
            <dl className="space-y-3">
              <Disclosure
                title={
                  <div className="flex items-center">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" className="mr-3 inline-block">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M14.27 6c-.55.95-.22 2.18.73 2.73.95.55 2.18.22 2.73-.73.55-.95.22-2.18-.73-2.73-.95-.55-2.18-.22-2.73.73z"></path>
                      <path d="M15.84 10.41l-2.6-1.5c-2.38-1.38-3.2-4.44-1.82-6.82l-1.73-1C8.1 3.83 8.6 7.21 10.66 9.4l-5.15 8.92 1.73 1 1.5-2.6 1.73 1-3 5.2 1.73 1 6.29-10.89a5.002 5.002 0 01.31 5.46l1.73 1c1.6-2.75 1.28-6.58-1.69-9.08zM12.75 3.8c.72.41 1.63.17 2.05-.55.41-.72.17-1.63-.55-2.05a1.501 1.501 0 00-1.5 2.6z"></path>
                    </svg>
                    Bewegung
                  </div>
                }
              >
                <P>
                  Sport und Bewegung sind ein kostenloses Mittel gegen Bluthochdruck. Dein Herz profitiert ungemein von Herz-Kreislauf-Training, das ganz unterschiedlich aussehen kann. Während manche den Hula Hoop Reifen schwingen, gehen
                  andere Menschen lieber Laufen oder Schwimmen, wieder andere bevorzugen High Intensive Intervall Training oder Tanzen. Wichtig ist hierbei gar nicht, was du machst, sondern dass du es machst. Optimalerweise trainierst du in
                  den verschiedenen fünf Trainingsbereichen, die sich in Intensität und Länge unterschiedliche provozieren lassen. Ein langer Lauf über 45 Minuten in gemütlichem Tempo wäre hierfür ein optimales Herz-Kreislauftraining, setzt
                  aber eine gute Grundfitness voraus. Also baue deine Ausdauer langsam auf und hab Geduld mit dir, damit senkst du die Wahrscheinlichkeit auf die Erkrankung an Bluthochdruck ungemein.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <GiKnifeFork className="mr-3 inline-block" size={24} />
                    Ernährung
                  </div>
                }
              >
                <P>
                  So umfassend wie das Thema Ernährung selbst, ist auch die Wirkweise auf den menschlichen Organismus. Leider muss man sagen, denn jede Form der Nahrungsaufnahme setzt unterschiedliche Stoffwechselprozesse in Gang, die
                  positive wie negative Konsequenzen nach sich ziehen können. Übermäßiger Konsum von Fastfood beispielsweise, kann Arteriosklerose, also eine Verengung der Blutgefäße mit sich bringen. Außerdem wird Übergewicht begünstigt,
                  was als Risikofaktor gilt. Gleiches gilt für zuckerhaltige Getränke und Speisen, die durch Wasser oder Tee ersetzt werden sollte. Alkohol und Kaffee sollte falls nötig nur in Maßen konsumiert werden.
                </P>
              </Disclosure>
              <Disclosure
                title={
                  <div className="flex items-center">
                    <GiMeditation className="mr-3 inline-block" size={24} />
                    Entspannung
                  </div>
                }
              >
                <P>
                  Gegen Bluthochdruck empfehle ich dir, das Autogene Training zu erlernen. Die Wirkung auf das vegetative Nervensystem und damit auch auf Blutdruck und Herz-Kreislauf-System ist wissenschaftlich nachgewiesen und ermöglicht
                  es dir wieder in die Regulation zu kommen. Dein Herz erlernt sozusagen, wieder zwischen An- und Entspannung umzuschalten. Autogenes Training kannst du bequem von zu Hause, in deinem Tempo erlernen, indem du unseren
                  Onlinekurs buchst. Du findest ihn über diesen Link (LINK AT). Sowohl unser Onlineangebot, als auch die Präsenzkurse sind zertifiziert, das bedeutet du profitierst von dem Zuschuss deiner Krankenversicherung, der mindestens
                  75 Euro nach Kursabschluss beträgt.
                </P>
              </Disclosure>
            </dl>
          </div>
          <div>
            <H2 className="mb-5 font-display">Zusammenfassung</H2>
            <P className="mb-3">
              Bluthochdruck ist oftmals eine Lebensstilerkrankung und kann durch gesundheitsbezogenes Verhalten verhindert werden. Deine Gewohnheiten und Rituale, dein Erleben, Denken und Fühlen nehmen Einfluss auf dein Herz, das
              prinzipiell erst einmal in der Lage ist, angemessen auf Stress oder herausfordernde Situationen zu reagieren. Diese sogenannte Herzratenvariabilität ist trainierbar, ebenso wie der Herzmuskel an auch. Insbesondere Ernährung
              und Herz-Kreislauf-Training sind gute und nebenwirkungsfreie Mittel gegen Bluthochdruck und stehen im direkten Zusammenhang mit der Gesundheit. Hauptsächlich ältere, aber auch übergewichtige Menschen sind betroffen und sollten
              daher regelmäßig Blutdruckmessungen durchführen, damit die Hypertonie erkannt und behandelt werden kann.
            </P>
            <P className="mb-3">
              Gegen Blutdruckbeschwerden helfen primär Entspannungsverfahren, wie Autogenes Training. Das Autogene Training ist ein Verfahren, bei dem sich Anwender*innen im Geiste Formeln vorsagen, die das Ziel haben, Entspannungszustände
              hervorzurufen. Dabei werden zunächst die sieben Grundformeln erlernt, über die du dich hier im{' '}
              <Link to="/blog/autogenes-training-fur-die-gesundheit-nutzen/" className="underline">
                Blogartikel "So kannst du Autogenes Training für deine Gesundheit nutzen"
              </Link>{' '}
              noch etwas genauer informieren kannst. Unser zertifizierter{' '}
              <a href="https://elopage.com/s/entspannungshelden/autogenes-training-onlinekurs-blutdruckdaten/payment" className="underline" target="_blank" rel="noreferrer noopener">
                Onlinekurs in Kombination mit Horst Kliers Blutdruckdaten App
              </a>
              , ist die perfekte Komposition aus Training und Analyse und ermöglicht es dir, einen direkten Erfolg sichtbar zu machen.
            </P>
            <Button text="Zum Kombi-Angebot" href="https://elopage.com/s/entspannungshelden/autogenes-training-onlinekurs-blutdruckdaten/payment" target="_blank" rel="noreferrer noopener" />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  {
    bildHeader: file(relativePath: { eq: "pages/herausforderungen/bluthochdruck/bluthochdruck_header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
